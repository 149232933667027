import React from 'react';
import { Blank } from 'grommet-icons';

const IconAward = (props) => {
  return (
    <Blank
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 54"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      {...props}
    >
      <path d="M21.949.031c-9.934 0-18 8.066-18 18 0 9.935 8.066 18 18 18 9.935 0 18-8.065 18-18 0-9.934-8.065-18-18-18zm0 2c8.831 0 16 7.17 16 16 0 8.831-7.169 16-16 16-8.83 0-16-7.169-16-16 0-8.83 7.17-16 16-16z" />
      <path d="M22.128 8C16.538 8 12 12.538 12 18.128s4.538 10.129 10.128 10.129 10.129-4.539 10.129-10.129S27.718 8 22.128 8zm0 2c4.487 0 8.129 3.642 8.129 8.128 0 4.487-3.642 8.129-8.129 8.129-4.486 0-8.128-3.642-8.128-8.129C14 13.642 17.642 10 22.128 10zM12.062 53.892L22 35.5 9 30-.062 46.892 9 45.196l3.062 8.696zm7.133-17.407l-6.746 12.484-2.132-6.054-6.643 1.243L9.9 32.553l9.295 3.932zM35 30l-12 6 9.938 17.892L36 45.196l9.062 1.696L35 30zm-9.237 6.855l8.47-4.235 6.845 11.492-6.395-1.197-2.155 6.12-6.765-12.18z" />
    </Blank>
  );
};

export default IconAward;
